<template>
  <svg
    id="Logo"
    class="app-logo"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 911.7 332.8"
    style="enable-background:new 0 0 911.7 332.8;"
    xml:space="preserve"
  >
    <g>
      <g id="Nodes">
        <g>
          <path
            class="st0"
            d="M359,241.7c-2.2,0-4.2,0.6-5.9,1.7l-20.5-21.5c2.4-2.9,3.8-6.7,3.8-10.7c0-5.2-2.3-9.9-6-13l16.3-25.2
  				c1.6,0.7,3.3,1,5.1,1c7.2,0,13-5.8,13-13c0-7.2-5.8-13-13-13c-1.8,0-3.5,0.4-5.1,1l-18.9-29.2c1.9-1.9,3-4.4,3-7.3
  				c0-5.7-4.6-10.4-10.4-10.4c-5.7,0-10.4,4.6-10.4,10.4c0,5.7,4.6,10.4,10.4,10.4c1.3,0,2.5-0.2,3.6-0.7l18.9,29.2
  				c-2.6,2.4-4.2,5.8-4.2,9.6c0,3.8,1.6,7.2,4.2,9.6l-16.3,25.3c-2.2-1.1-4.7-1.7-7.4-1.7c-7,0-13.1,4.2-15.7,10.3l-26.6-7.6
  				c-0.3,1.4-0.5,2.9-0.8,4.4l26.2,7.5c-0.1,0.8-0.2,1.7-0.2,2.6c0,7.6,5,14,11.8,16.3l-11.2,60c-0.1,0-0.1,0-0.2,0
  				c-7.2,0-13.1,5.9-13.1,13.1s5.9,13.1,13.1,13.1c7.2,0,13.1-5.9,13.1-13.1c0-2.4-0.7-4.7-1.8-6.6l38.4-32.3
  				c1.9,1.5,4.3,2.4,6.9,2.4c6.1,0,11.1-5,11.1-11.1C370.1,246.7,365.1,241.7,359,241.7z M349.2,258.2l-38.4,32.3
  				c-1.1-1-2.4-1.7-3.9-2.2l11.2-60c0.3,0,0.6,0,0.9,0c3.8,0,7.3-1.2,10.1-3.3l20.6,21.5c-1.3,1.8-2,4-2,6.3
  				C347.9,254.8,348.3,256.6,349.2,258.2z"
          />
        </g>
        <g>
          <path
            class="st0"
            d="M74.5,143.1l13.1,3.8c-0.2-0.9-0.4-1.8-0.7-2.8c-0.2-0.6-0.4-1.4-0.6-2.2l-10.5-3c0.1-0.5,0.1-1.1,0.2-1.6
  				c0.5-7.7-4.2-14.6-11-17.3l15-60.2c6.5,0.4,12-4.6,12.4-11.1c0.4-6.5-4.6-12.1-11.1-12.5c-6.5-0.4-12.1,4.6-12.5,11.1
  				c-0.1,2.1,0.3,4.1,1.2,5.8L31.5,81.8c-2.4-2.4-5.7-4-9.4-4.2c-8.1-0.5-15,5.7-15.5,13.8c-0.5,8.1,5.7,15,13.8,15.5
  				c3.1,0.2,6.1-0.6,8.5-2.1l16.7,19.7c-2.6,2.8-4.3,6.6-4.5,10.7c-0.6,9.6,6.7,17.8,16.2,18.4l2,33.8c-4.8,1.2-8.5,5.4-8.8,10.7
  				c-0.4,6.5,4.5,12,11,12.4c6.5,0.4,12-4.5,12.4-11c0.4-6.2-4.1-11.5-10.2-12.3l-2-33.8C67.4,152.2,72.2,148.4,74.5,143.1z
  				 M60.5,118.9c-0.3,0-0.7-0.1-1-0.1c-3.9-0.2-7.5,0.8-10.5,2.8l-16.7-19.8c2.1-2.4,3.4-5.4,3.6-8.7c0.2-2.8-0.5-5.4-1.7-7.7
  				l38.4-28.6c0.9,0.8,1.9,1.5,3,2L60.5,118.9z"
          />
        </g>
      </g>
      <g id="Mask">
        <g>
          <path
            class="st0"
            d="M291.1,240l-2.3-1.3c-0.6-0.4-1.4-0.1-1.8,0.5l-23.5,41.4c-0.4,0.6-0.1,1.4,0.5,1.8l2.3,1.3
  				c0.6,0.4,1.4,0.1,1.8-0.5l23.5-41.4C291.9,241.2,291.7,240.3,291.1,240z"
          />
        </g>
        <g>
          <path
            class="st0"
            d="M99.1,239.2c-0.4-0.6-1.2-0.9-1.8-0.5L95,240c-0.6,0.4-0.9,1.2-0.5,1.8l23.5,41.4c0.4,0.6,1.2,0.9,1.8,0.5
  				l2.3-1.3c0.6-0.4,0.9-1.2,0.5-1.8L99.1,239.2z"
          />
        </g>
        <g>
          <ellipse class="st0" cx="193.1" cy="295.9" rx="21.2" ry="12.9" />
        </g>
        <g>
          <path
            class="st0"
            d="M287.6,120.1C278.8,114,249,93.3,193,93.3s-85.8,20.7-94.7,26.9s-7.5,15.3-5.5,22.3c2,7,2.1,12.2,2.3,17.1
  				c0.2,4.8-1.4,16.9,17.2,21.5c0.5,0.1,0.9,0.5,0.9,1c0.4,4.3,2.7,24.9,11.4,47.7l-1.3,0.7c-0.6,0.3-1.3,0.2-1.7-0.3
  				c-1.1-1.3-3.5-3.3-6.8-1.4l-11.3,6.4c-0.6,0.4-0.9,1.2-0.5,1.8l23.5,41.4c0.4,0.6,1.2,0.9,1.8,0.5l11.3-6.4
  				c3.3-1.9,2.8-5,2.2-6.6c-0.2-0.6,0-1.3,0.6-1.6l0.7-0.4c3.4,4.4,7.1,8.6,11.2,12.4c0.1,0.1,0.1,0.1,0.2,0.2
  				c-5.8,30.8,17.2,47.3,38.3,47.3c21.1,0,44.1-16.5,38.3-47.3c0,0,0.1,0,0.1-0.1c4.1-3.9,7.9-8.1,11.2-12.5l0.8,0.4
  				c0.6,0.3,0.8,1,0.6,1.6c-0.6,1.6-1.1,4.7,2.2,6.6l11.3,6.4c0.6,0.4,1.4,0.1,1.8-0.5l23.5-41.4c0.4-0.6,0.1-1.4-0.5-1.8l-11.3-6.4
  				c-3.3-1.9-5.7,0.2-6.8,1.4c-0.4,0.5-1.1,0.6-1.7,0.3l-1.4-0.8c8.7-22.7,11-43.3,11.4-47.6c0-0.5,0.4-0.9,0.9-1
  				c18.6-4.6,17-16.7,17.2-21.5c0.2-4.9,0.3-10.1,2.3-17.1C295.1,135.4,296.5,126.3,287.6,120.1z M193.1,316.4
  				c-15.1,0-27.3-8.3-27.3-18.6c0-10.3,12.2-18.6,27.3-18.6c15.1,0,27.3,8.3,27.3,18.6C220.4,308.1,208.2,316.4,193.1,316.4z
  				 M238.8,240.7c-12,12.8-15.3-10.6-45.8-10.8c-30.4,0.2-33.7,23.6-45.8,10.8c-17.4-18.5-21.1-40.1-20.6-59.6
  				c1.2-44.2,30.6-61,66.4-61s65.2,16.8,66.4,61C259.9,200.6,256.1,222.2,238.8,240.7z"
          />
        </g>
      </g>
      <g id="Helmet">
        <path
          class="st0"
          d="M193,89.5c38.2,0,64.2,9.6,79.7,17.7c3.6,1.9,8-0.8,7.9-4.9c-1.5-49.7-30.3-69.8-51.5-79.4
  			c-7.9-3.6-13.4-5.4-13.4-5.4c-1.6-0.6-3.4,0.3-3.8,1.9c-3.5,12.4-5.5,32.3-6.5,46.5c-0.1,1-1.6,1-1.6-0.1
  			c-0.2-21.5,1.5-40.1,2.7-49.6c0.2-1.3-0.6-2.6-1.9-3c-5.4-2-9.5-3.5-11-4c-0.2-0.1-0.4-0.1-0.6-0.1s-0.4,0-0.6,0.1
  			c-1.5,0.5-5.6,2-11,4c-1.2,0.5-2,1.7-1.9,3c1.1,9.5,2.9,28.1,2.7,49.6c0,1-1.5,1.1-1.6,0.1c-1.1-14.2-3-34.2-6.5-46.5
  			c-0.5-1.6-2.2-2.5-3.8-1.9c0,0-5.5,1.9-13.4,5.4c-21.2,9.6-49.9,29.7-51.5,79.4c-0.1,4.1,4.2,6.9,7.9,4.9
  			C128.8,99.2,154.8,89.5,193,89.5z"
        />
      </g>
      <g id="Title">
        <path class="st0" d="M429,77.1h37V262h-37V77.1z" />
        <path
          class="st0"
          d="M600.5,262h-43.1L501.2,77.1h42.1L579,217.6l36.7-140.5h41.1L600.5,262z"
        />
        <path class="st0" d="M691.9,77.1h37V262h-37V77.1z" />
        <path
          class="st0"
          d="M866,119.8c0-5.6-3.1-8.7-8.7-8.7h-37c-5.6,0-8.7,3.1-8.7,8.7v17.5c0,5.6,3.1,7.4,8.7,8.7l47.8,10.3
  			c20,4.4,37,16.4,37,37v33.4c0,21.6-14.9,36.5-36.5,36.5h-57.5c-21.6,0-36.5-14.9-36.5-36.5V203h36v16.4c0,5.6,3.1,8.7,8.7,8.7
  			h40.1c5.6,0,8.7-3.1,8.7-8.7v-19.5c0-5.7-3.1-7.4-8.7-8.7l-47.8-10.3c-20-4.4-37-16.4-37-37v-31.3c0-21.6,14.9-36.5,36.5-36.5
  			h54.4c21.6,0,36.5,14.9,36.5,36.5v21.6h-36V119.8z"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
svg {
  fill: currentColor;
}
</style>
